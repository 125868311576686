<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1"> Datalogger </v-stepper-step>

      <v-divider />

      <v-stepper-step :complete="e1 > 2" step="2"> Cihaz tipi </v-stepper-step>

      <v-divider />

      <v-stepper-step step="3"> Veri </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-row class="pt-4" align="center">
          <v-col cols="12" sm="6">
            <v-select
              v-model="santralModel"
              outlined
               
              dense
              :items="santralList"
              :menu-props="{ maxHeight: '300' }"
              label="santral"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="dataloggerModel"
              outlined
               
              dense
              :items="dataloggerList"
              :menu-props="{ maxHeight: '300' }"
              label="Datalogger"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="sensorTypeModel"
              :items="sensorTypeList"
              :menu-props="{ maxHeight: '300' }"
              label="Cihaz Listesi"
              outlined
               
              dense
            />
          </v-col>
        </v-row>
        <v-btn color="primary" @click="e1 = 2" dense   outlined>
          Sonraki
        </v-btn>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-row class="pt-4" align="center">
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.sensorDeviceSerial"
              label="Serial"
              outlined
               
              dense
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.SENSORNAME"
              label="SENSORNAME"
              outlined
              dense
               
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.ID"
              label="ID"
              outlined
              dense
               
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-model="formdata.SENSOR"
              label="SENSOR"
              outlined
              dense
               
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-select
              v-model="formdata.sensorStatus"
              :items="['Active', 'Passive']"
              :menu-props="{ maxHeight: '300' }"
              label="Durum"
              outlined
              dense
               
            />
          </v-col>
        </v-row>

        <v-btn color="primary" @click="e1 = 3" dense   outlined>
          Sonraki
        </v-btn>
      </v-stepper-content>

      <v-stepper-content step="3">
        <v-row class="mb-0 mt-0 pt-0 pb-0">
          <v-col cols="12">
            <v-data-table
              v-model="deviceParameterModel"
              :headers="devheaders"
              :items="deviceParameterList"
              class="elevation-1"
              dense
              item-key="value"
              show-select
              :items-per-page="itemPerPage"
            />
          </v-col>

          <v-col cols="12" class="d-flex">
            <v-col class="col-2">
              <v-btn @click="setSensorCreate" dense   outlined>
                Kaydet
              </v-btn>
            </v-col>
            <v-col class="col-2">
              <v-btn color="primary" @click="e1 = 1" dense   outlined>
                Sonraki
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { getDeviceList, getDataloggerList } from "@/api/Request/farm";
import { setSensor } from "@/api/Request/sensor";
import { getInverterTypeList } from "@/api/Request/inverter";
import { getSantralList } from "@/api/Request/santral";

import helper from "@/api/helper";

export default {
  props: ["sensordata"],
  data: () => ({
    e1: 1,
    deviceTypeModel: "SENSOR",
    sensorTypeModel: "",
    sensorTypeList: [],
    inverterTypeData: [],
    itemPerPage: 10,
    deviceParameterList: [],
    deviceParamHeader: [],
    deviceParameterModel: [],
    dataloggerList: [],
    devheaders: [],
    dataloggerModel: "",
    deviceList: [],
    deviceModel: "",
    dataloggerData: [],
    santralList: [],
    santralData: {},
    santralModel: "",

    formdata: {
      santral: "",
      sensorDeviceSerial: "",
      SENSORMODEL: "",
      ID: "",
      SENSORNAME: "",
      SENSOR: "",
      sensorDeviceModel: "",
      sensorManufacture: "",
      sensorStatus: "",
    },
  }),
  watch: {
    deviceTypeModel() {
      // this.getInverterTypeList();
    },
    deviceParameterModel() {},

    dataloggerModel() {
      /*    console.log(this.dataloggerModel);
      console.log(this.dataloggerData);
      const temp = this.dataloggerData[this.dataloggerModel];
      this.formdata.santral = temp.santral; */
    },
    sensorTypeModel() {
      this.getInverterType();
    },

    santralModel() {
      if (this.santralData.length > 0) {
        this.formdata.santral = this.santralData[this.santralModel].santral;
      }

      this.getDatalogger();
    },
    sensordata() {
      this.santralModel = "";
      this.dataloggerModel = "";
      this.sensorTypeModel = 0;
      this.santralData = {};
      this.formdata = {
        santral: "",
        sensorDeviceSerial: "",
        SENSORMODEL: "",
        ID: "",
        SENSORNAME: "",
        SENSOR: "",
        sensorDeviceModel: "",
        sensorManufacture: "",
        sensorStatus: "",
      };
      this.deviceParameterList = [];
      this.deviceParameterModel = [];
      this.e1 = 1;
      this.deviceTypeModel = "SENSOR";
      this.deviceModel = "";
      this.setSensorDataFunc();
    },
  },
  mounted() {
    const storage = window.localStorage;
    const userCompany = JSON.parse(
      window.atob(storage.getItem("user_company"))
    );
    this.companyCode = userCompany.companyCode;
    this.prefix = userCompany.prefix;

    this.getSantralList();
    this.getSensorTypeList();
    this.setSensorDataFunc();
  },
  methods: {
    setSensorDataFunc() {
      if (this.sensordata.general_measure_list !== undefined) {
        this.devheaders = [
          { text: "text", value: "text" },
          { text: "key", value: "key" },
          { text: "unit", value: "unit" },
          { text: "type", value: "type" },
          { text: "formuletype", value: "formuletype" },
        ];
        this.deviceParameterList = this.sensordata.general_measure_list;
        this.deviceParameterModel = this.sensordata.general_measure_list;
        this.sensorTypeModel = this.sensordata.inverterDeviceModel;

        this.formdata = { ...{}, ...this.sensordata };

        this.santralModel = this.sensordata.santral;
        this.dataloggerModel = this.sensordata.dataloggerName;
      }
    },
    getSantralList() {
      const params1 = {
        condiniton: { prefix: this.prefix },
      };

      getSantralList(params1).then((res) => {
        const santralList = [];
        const santralData = {};

        Object.keys(res.data.data).forEach((k) => {
          santralData[res.data.data[k].santral] = res.data.data[k];
          santralList.push({
            text: res.data.data[k].santral,
            value: res.data.data[k].santral,
          });
        });
        this.santralData = santralData;
        this.santralList = santralList;
      });
    },
    // datalogger listesi
    getDatalogger() {
      const params = {
        condiniton: {
          prefix: this.prefix,
          santral: this.santralModel,
        },
      };
      getDataloggerList(params).then((res) => {
        const devList = [];
        const deviceList = {};

        Object.keys(res.data.data).forEach((i) => {
          deviceList[res.data.data[i].deviceName] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].deviceName}`,
            value: `${res.data.data[i].deviceName}`,
          });
        });

        this.dataloggerData = deviceList;
        this.dataloggerList = devList;
      });
    },

    ///  inverter tipleri listesi
    getSensorTypeList() {
      const params = {
        condiniton: {
          DeviceType: this.deviceTypeModel,
        },
      };
      console.log(this.deviceTypeModel);
      console.log(params);

      getDeviceList(params).then((res) => {
        const devList = [];
        const deviceData = {};
        this.deviceData = res.data.data;
        Object.keys(res.data.data).forEach((i) => {
          const dev = res.data.data[i].DeviceId;
          deviceData[dev] = res.data.data[i];
          devList.push({
            text: `${res.data.data[i].DeviceManufacture} ${res.data.data[i].DeviceModel}`,
            value: res.data.data[i].DeviceId,
          });
        });

        this.deviceData = deviceData;
        this.sensorTypeList = devList;
      });
    },

    getInverterType() {
      this.devheaders = [
        { text: "text", value: "value" },
        { text: "key", value: "key" },
        { text: "unit", value: "unit" },
        { text: "type", value: "type" },
        { text: "formuletype", value: "formuletype" },
      ];

      if (helper.is_Empty(this.dataloggerModel)) {
        return;
      }

      if (helper.is_Empty(this.deviceData)) {
        return;
      }
      console.log(this.sensorTypeModel);
      console.log(this.dataloggerModel);
      console.log(this.dataloggerData);
      console.log(this.deviceData);
      const params = {
        condiniton: {
          SENSORMODEL: this.deviceData[this.sensorTypeModel].Model,
        },
      };

      if (this.dataloggerData[this.dataloggerModel] !== undefined) {
        const temp = this.dataloggerData[this.dataloggerModel];
        params.condiniton.Datalogger = temp.Model;
      }

      getInverterTypeList(params).then((res) => {
        this.deviceParameterList = res.data.data[0].general_measure_list;
        this.deviceParameterModel = res.data.data[0].general_measure_list;

        this.itemPerPage = this.deviceParameterList.length;
      });
    },

    setSensorCreate() {
      // eslint-disable-next-line radix

      const temp = this.dataloggerData[this.dataloggerModel];

      const data = {
        SENSORNAME: this.formdata.SENSORNAME,
        ID: this.formdata.ID,
        santral: this.santralModel,
        SENSOR: this.formdata.SENSOR,
        sensorStatus: this.formdata.sensorStatus,

        SENSORMODEL: this.deviceData[this.sensorTypeModel].Model,
        sensorManufacture:
          this.deviceData[this.sensorTypeModel].DeviceManufacture,
        sensorDeviceModel: this.deviceData[this.sensorTypeModel].DeviceModel,

        sensorDeviceSerial: this.formdata.Serial,
        general_measure_list: this.deviceParameterModel,

        dataloggerName: temp.deviceName,
        dataloggerDeviceModel: temp.DeviceModel,
        dataloggerTypeDeviceId: temp.DeviceId,
        santralCode: temp.santralCode,
        timeInterval: temp.timeInterval,
      };

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
          santralCode: this.santralCode,
          deviceType: this.deviceTypeModel,
          SENSOR: data.SENSOR,
          santral: data.santral,
        },
        data,
      };

      setSensor(params).then((res) => {
        // console.log(res);
      });
    },
  },
};
</script>
